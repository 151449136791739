import axios from 'axios';
import { ethers } from 'ethers';
import addrAbi from '../abis/addr.json'; // Ensure the path to addr.json is correct

const evm_base_url = "https://evm-rpc.sei-apis.com"; // Your EVM RPC URL
const contractAddress = "0x0000000000000000000000000000000000001004"; // Precompile contract address for address conversion
const provider = new ethers.providers.JsonRpcProvider(evm_base_url);
const addressPrecompileContract = new ethers.Contract(contractAddress, addrAbi, provider);

export const fetchTransactions = async (address) => {
    const url = `https://celatone-api-prod.alleslabs.dev/v1/sei/pacific-1/accounts/${address}/txs?limit=5000&offset=0&is_wasm=true&is_move=false&is_initia=false&is_send=true&is_ibc=false&is_instantiate=false&is_store_code=false&is_execute=false&is_migrate=false&is_update_admin=false&is_clear_admin=false&is_move_publish=false&is_move_upgrade=false&is_move_execute=false&is_move_script=false&is_opinit=false&is_signer=true`;
    
    try {
        const response = await axios.get(url);
        if (response.status !== 200) {
            throw new Error(`Error fetching data: ${response.status}`);
        }
        return response.data;
    } catch (error) {
        console.error(`Error fetching transactions for address ${address}:`, error);
        return null;
    }
};

export const getLinkedAddresses = async (address) => {
  if (address.startsWith("0x")) {
    try {
      const seiAddress = await addressPrecompileContract.getSeiAddr(address);
      return { evm_address: address, sei_address: seiAddress };
    } catch (error) {
      return { evm_address: address, sei_address: "" };
    }
  } else if (address.startsWith("sei")) {
    try {
      const evmAddress = await addressPrecompileContract.getEvmAddr(address);
      return { sei_address: address, evm_address: evmAddress };
    } catch (error) {
      return { sei_address: address, evm_address: "" };
    }
  }
  return { evm_address: "", sei_address: "" };
};

export const processTransactions = async (transactions, address) => {
    const fundedWallets = [];
    if (transactions && Array.isArray(transactions.items)) {
        for (let item of transactions.items) {
            for (let message of item.messages) {
                if (message.type === "/cosmos.bank.v1beta1.MsgSend") {
                    const denom = message.detail.amount[0].denom;
                    const fromAddress = message.detail.from_address;
                    const toAddress = message.detail.to_address;
                    const amount = parseInt(message.detail.amount[0].amount) / 1e6;
                    const timestamp = new Date(item.created).toLocaleString().replace(/,/g, '');

                    let seiAmount = null;
                    let usdcAmount = null;
                    let usdtAmount = null;

                    if (denom === "usei") {
                        seiAmount = amount;
                    } else if (denom === "ibc/CA6FBFAF399474A06263E10D0CE5AEBBE15189D6D4B2DD9ADE61007E68EB9DB0") {
                        usdcAmount = amount;
                    } else if (denom === "ibc/6C00E4AA0CC7618370F81F7378638AE6C48EFF8C9203CE1C2357012B440EBDB7") {
                        usdtAmount = amount;
                    }

                    const linkedAddresses = await getLinkedAddresses(toAddress);
                    fundedWallets.push({ 
                        fromAddress, 
                        toAddress, 
                        evmAddress: linkedAddresses.evm_address,
                        seiAmount, 
                        usdcAmount, 
                        usdtAmount, 
                        timestamp 
                    });
                }
            }
        }
    } else {
        console.error('Unexpected transaction structure:', transactions);
    }

    return fundedWallets;
};

export const exportBankrollToCSV = (transactions, wallet) => {
    const headers = ['Sending Wallet', 'Receiving Wallet', 'EVM Wallet', 'SEI Amount', 'USDC Amount', 'USDT Amount', 'Timestamp'];
    const csvRows = [headers.join(',')];

    transactions.forEach(tx => {
        const row = [
            tx.fromAddress,
            tx.toAddress,
            tx.evmAddress,
            tx.seiAmount,
            tx.usdcAmount,
            tx.usdtAmount,
            tx.timestamp
        ];
        csvRows.push(row.join(','));
    });

    const csvContent = `data:text/csv;charset=utf-8,${csvRows.join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `bankroll_${wallet}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};