import React, { useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import './Table.css';

const Table = ({ results = [], copyToClipboard, exchangeData, copiedAddress, showEvm, selectedTokens, duplicateFundingWallets }) => {
  const data = React.useMemo(() => results, [results]);

  const truncateAddress = (address) => {
    if (!address || address === 'No Linked Address') {
      return address;
    }
    return `${address.substring(0, 6)}....${address.substring(address.length - 6)}`;
  };

  const [clickedButton, setClickedButton] = useState(null);

  const handleCopyClick = (value, uniqueKey) => {
    copyToClipboard(value, uniqueKey);
    setClickedButton(uniqueKey);
    setTimeout(() => {
      setClickedButton(null);
    }, 2000);
  };

  const columns = React.useMemo(() => {
    const baseColumns = [
      {
        Header: 'SEI Address',
        accessor: 'wallet_address',
        Cell: ({ value, row: { original, index } }) => {
          const uniqueKey = `sei-${index}`;
          const seiAddress = original.wallet_type === 'sei' || value.startsWith('sei') ? value : 'SEI Address Not Linked';
          return (
            <div className="cell-container monospace">
              <div className="address-row-column">
                <div className="address-row">
                  {seiAddress !== 'SEI Address Not Linked' ? (
                    <a href={`https://www.seiscan.app/pacific-1/accounts/${value}`} target="_blank" rel="noopener noreferrer">
                      {truncateAddress(seiAddress)}
                    </a>
                  ) : (
                    <span>{seiAddress}</span>
                  )}
                  {seiAddress !== 'SEI Address Not Linked' && (
                    <button className="copy-button" onClick={() => handleCopyClick(value, uniqueKey)}>
                      {clickedButton === uniqueKey ? '✔️' : '📋'}
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        }
      },
      showEvm && {
        Header: 'EVM Address',
        accessor: 'evm_address',
        Cell: ({ value, row: { index } }) => {
          const uniqueKey = `evm-${index}`;
          return (
            <div className="cell-container monospace">
              {value === 'No EVM Wallet Linked' ? (
                <div>No Linked Address</div>
              ) : (
                <div className="address-row-column">
                  <div className="address-row">
                    <a href={`https://seitrace.com/address/${value}`} target="_blank" rel="noopener noreferrer">
                      {truncateAddress(value)}
                    </a>
                    <button className="copy-button" onClick={() => handleCopyClick(value, uniqueKey)}>
                      {clickedButton === uniqueKey ? '✔️' : '📋'}
                    </button>
                  </div>
                </div>
              )}
            </div>
          );
        }
      },
      {
        Header: 'Current Balance',
        accessor: 'current_balance'
      },
      {
        Header: 'Funding Address',
        accessor: 'funding_wallets',
        Cell: ({ value, row: { original, index } }) => {
          const hasBalance = parseFloat(original.current_balance) > 0;
          const showFunding = hasBalance || original.wallet_address !== 'SEI Address Not Linked';
          return (
            <div className="cell-container monospace">
              {showFunding ? (
                value && value.length > 0 ? (
                  value.map((wallet, i) => {
                    const uniqueKey = `funding-${index}-${i}`;
                    return (
                      <div key={i} className={`address-row-column address-row-column-${i}`}>
                        {wallet !== 'EVM Funded' ? (
                          <div className="address-row">
                            <a href={`https://www.seiscan.app/pacific-1/accounts/${wallet}`} target="_blank" rel="noopener noreferrer">
                              {truncateAddress(wallet)}
                            </a>
                            <button className="copy-button" onClick={() => handleCopyClick(wallet, uniqueKey)}>
                              {clickedButton === uniqueKey ? '✔️' : '📋'}
                            </button>
                            {exchangeData[wallet] && (
                              <>
                                <a href={exchangeData[wallet].url} target="_blank" rel="noopener noreferrer" data-tooltip-id={`tooltip-${wallet}`}>
                                  <img src={exchangeData[wallet].logo} alt={exchangeData[wallet].name} className="exchange-logo right" />
                                </a>
                              </>
                            )}
                          </div>
                        ) : (
                          <span>EVM Funded</span>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <span>EVM Funded</span>
                )
              ) : (
                <span>N/A</span>
              )}
            </div>
          );
        }
      },
      {
        Header: 'Funding Amount',
        accessor: 'amount',
        Cell: ({ value, row: { original } }) => {
          const hasBalance = parseFloat(original.current_balance) > 0;
          return original.wallet_address === 'SEI Address Not Linked' && !hasBalance ? 'N/A' : (value ? value.toLocaleString() : 'Unknown');
        }
      },
      {
        Header: 'Total TXs',
        accessor: 'total_tx_count'
      },
      {
        Header: 'Funding Date',
        accessor: 'timestamp',
        Cell: ({ value, row: { original } }) => {
          const hasBalance = parseFloat(original.current_balance) > 0;
          return original.wallet_address === 'SEI Address Not Linked' && !hasBalance ? 'N/A' : (value ? new Date(value).toLocaleString().replace(/,/g, '') : 'Unknown');
        }
      },
      ...selectedTokens.map(tokenName => ({
        Header: tokenName,
        accessor: tokenName,
        Cell: ({ value }) => value ? parseInt(value).toLocaleString() : '0'
      }))
    ].filter(Boolean);

    return baseColumns;
  }, [copyToClipboard, exchangeData, copiedAddress, showEvm, selectedTokens, clickedButton]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy);

  const duplicateFundingWalletsCount = duplicateFundingWallets.length;

  const sortedDuplicateFundingWallets = duplicateFundingWallets.sort((a, b) => {
    const isAExchange = exchangeData[a.funding_wallet] !== undefined;
    const isBExchange = exchangeData[b.funding_wallet] !== undefined;

    if (isAExchange && !isBExchange) return -1;
    if (!isAExchange && isBExchange) return 1;
    return 0;
  });

  return (
    <div className="table-container">
      <div className="summary">
        {duplicateFundingWalletsCount > 0 && (
          <div className="duplicate-section"><hr />
            <h3>Duplicate Funding Addresses ({duplicateFundingWalletsCount})</h3>
            <ul>
              {sortedDuplicateFundingWallets.map((item, index) => (
                <React.Fragment key={index}>
                  {item.funding_wallet === "EVM Funded" && <br />}
                  <li>
                    <span className="monospace">{item.funding_wallet}</span>
                    {exchangeData[item.funding_wallet] && (
                      <img src={exchangeData[item.funding_wallet].logo} alt={exchangeData[item.funding_wallet].name} className="exchange-logo right" />
                    )}
                    <br />
                    <span className="monospace">{item.wallets.join(', ')}</span>
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </div>
        )}
        <hr />
        <p>Total Addresses Sniffed: {results.length}</p>
      </div>
      <div className="table-scroll-container">
        <table {...getTableProps()} className="results-table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="header-content">
                      <span className="header-text">{column.render('Header')}</span>
                      <span className="sort-arrows">
                        <span>🔼</span>
                        <span>🔽</span>
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()} className={cell.column.id === 'wallet_address' || cell.column.id === 'evm_address' || cell.column.id === 'funding_wallets' ? 'monospace' : ''}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
