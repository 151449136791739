import axios from 'axios'; // Correct import for ES6 modules
import { ethers } from 'ethers';

export async function getWalletBalance(walletAddress) {
    const rpcUrl = 'https://evm-rpc.sei-apis.com';

    const data = {
        jsonrpc: "2.0",
        method: "eth_getBalance",
        params: [
            walletAddress,
            "latest"
        ],
        id: 1
    };

    try {
        const response = await axios.post(rpcUrl, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.data.result) {
            const balanceInWei = response.data.result;
            const balanceInEther = parseFloat(ethers.utils.formatUnits(balanceInWei, 18)); // Convert Wei to Ether
            return balanceInEther.toFixed(6); // Return the balance formatted to 6 decimal places
        } else {
            return '0.000000'; // If the result is null or undefined, return zero
        }
    } catch (error) {
        console.error('Error fetching balance:', error);
        return '0.000000'; // Return zero balance in case of an error
    }
}
