// contracts.js
const contracts = {
  $POPO: '0xC18b6a15FB0ceaf5eb18696EeFCb5bc7b9107149',
  $gonad: '0xd78BE621436e69C81E4d0e9f29bE14C5EC51E6Ae',
  $FROY: '0x9134d733AaC991b96E899af657C717276A627E32',
  $SAKE: '0xe85dC0CCECa105755753Fef452C091dEF5324138',
  $BOOBLE: '0xF20903d034B12a7266055FE97cEBdB9199Ec6925'
};

export default contracts;
