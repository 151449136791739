import React, { useState, useEffect } from 'react';
import { fetchTransactions, processTransactions, exportBankrollToCSV } from '../services/bankrollApi';
import Footer from './Footer';
import logo from '../logos/logo.png';
import { Link } from 'react-router-dom';
import { useTable, useSortBy } from 'react-table';
import './Bankroll.css';

function Bankroll() {
  const [wallet, setWallet] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [error, setError] = useState('');
  const [isRunning, setIsRunning] = useState(false);
  const [removeDuplicates, setRemoveDuplicates] = useState(false);
  const [copiedAddress, setCopiedAddress] = useState('');
  const [hasDuplicates, setHasDuplicates] = useState(false);
  const [showUSDC, setShowUSDC] = useState(false);
  const [showUSDT, setShowUSDT] = useState(false);

  const hasSpecialCharacters = (address) => {
    const specialCharPattern = /[^a-zA-Z0-9]/;
    return specialCharPattern.test(address);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsRunning(true);

    const walletList = wallet.split(/[\n,]+/).map(wallet => wallet.trim()).filter(wallet => wallet);

    if (walletList.length > 1) {
      setError('You can only enter one address at a time.');
      setIsRunning(false);
      return;
    }

    if (wallet.startsWith('0x')) {
      setError("Please submit SEI native addresses. EVM ('0x') addresses are not currently supported.");
      setIsRunning(false);
      return;
    }

    if (hasSpecialCharacters(wallet)) {
      setError("I don't think SEI addresses contain special characters!");
      setIsRunning(false);
      return;
    }

    if (!wallet.startsWith('sei') || wallet.length !== 42) {
      setError('All addresses must start with "sei" and be 42 characters long.');
      setIsRunning(false);
      return;
    }

    try {
      const transactionData = await fetchTransactions(wallet);
      if (transactionData && transactionData.items && transactionData.items.length > 0) {
        const processedData = await processTransactions(transactionData, wallet);
        setTransactions(processedData);
        setFilteredTransactions(processedData);
        checkForDuplicates(processedData);
        setShowUSDC(processedData.some(tx => tx.usdcAmount > 0));
        setShowUSDT(processedData.some(tx => tx.usdtAmount > 0));
      } else {
        setError('Address has never sent SEI');
        setTransactions([]);
        setFilteredTransactions([]);
        setHasDuplicates(false);
        setShowUSDC(false);
        setShowUSDT(false);
      }
    } catch (error) {
      console.error('Error fetching transaction data:', error);
      setError('Error fetching transaction data. Please try again.');
    } finally {
      setIsRunning(false);
    }
  };

  const checkForDuplicates = (transactions) => {
    const seiTransactions = transactions.filter(tx => tx.seiAmount > 0);

    const addressCounts = seiTransactions.reduce((acc, tx) => {
      acc[tx.toAddress] = (acc[tx.toAddress] || 0) + 1;
      return acc;
    }, {});

    const hasDuplicates = Object.values(addressCounts).some(count => count > 1);
    setHasDuplicates(hasDuplicates);
  };

  const applyDuplicateFilter = (transactions, removeDuplicates) => {
    if (removeDuplicates) {
      const uniqueTransactions = [];
      const seenAddresses = new Map();

      transactions.forEach(tx => {
        if (tx.seiAmount > 0) {  // Only consider SEI transactions for duplicates
          if (seenAddresses.has(tx.toAddress)) {
            const existingTx = seenAddresses.get(tx.toAddress);
            existingTx.seiAmount += tx.seiAmount;
          } else {
            seenAddresses.set(tx.toAddress, { ...tx });
          }
        }
      });

      seenAddresses.forEach((tx, address) => {
        if (transactions.filter(t => t.toAddress === address && t.seiAmount > 0).length > 1) {
          tx.seiAmount = `${tx.seiAmount.toLocaleString()} (Total)`;
        } else {
          tx.seiAmount = tx.seiAmount.toLocaleString();
        }
        uniqueTransactions.push(tx);
      });

      uniqueTransactions.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

      if (JSON.stringify(filteredTransactions) !== JSON.stringify(uniqueTransactions)) {
        setFilteredTransactions(uniqueTransactions);
      }
    } else {
      if (JSON.stringify(filteredTransactions) !== JSON.stringify(transactions)) {
        setFilteredTransactions(transactions);
      }
    }
  };

  const calculateSummary = (transactions) => {
    return transactions.reduce((sum, tx) => {
      const amount = typeof tx.seiAmount === 'string' ? parseFloat(tx.seiAmount.replace(/[^\d.-]/g, '')) : tx.seiAmount;
      return sum + amount;
    }, 0);
  };

  const handleToggleDuplicates = () => {
    const newRemoveDuplicates = !removeDuplicates;
    setRemoveDuplicates(newRemoveDuplicates);
    applyDuplicateFilter(transactions, newRemoveDuplicates);
  };

  const copyToClipboard = (text, rowIndex) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedAddress(`${text}-${rowIndex}`);
      setTimeout(() => {
        setCopiedAddress('');
      }, 2000);
    }, (err) => {
      console.error('Error copying to clipboard', err);
    });
  };

  const truncateAddress = (address) => {
    if (!address || address === 'No Linked Address') {
      return address;
    }
    return `${address.substring(0, 6)}....${address.substring(address.length - 6)}`;
  };

  const numberOfWallets = transactions.length;
  const totalSeiSent = calculateSummary(filteredTransactions);

  const duplicateAddresses = filteredTransactions.filter(tx => tx.seiAmount > 0).reduce((acc, tx) => {
    if (!acc[tx.toAddress]) {
      acc[tx.toAddress] = { totalSei: 0, transactions: 0 };
    }
    acc[tx.toAddress].totalSei += tx.seiAmount;
    acc[tx.toAddress].transactions += 1;
    return acc;
  }, {});

  const duplicateAddressSummary = Object.entries(duplicateAddresses)
    .filter(([_, { transactions }]) => transactions > 1)
    .map(([address, { totalSei, transactions }]) => ({ address, totalSei, transactions }));

  const columns = React.useMemo(() => {
    const baseColumns = [
      {
        Header: 'Sending Wallet',
        accessor: 'fromAddress',
        Cell: ({ value, row: { index } }) => (
          <div className="cell-container monospace">
            <div className="address-row-column">
              <div className="address-row">
                <a href={`https://www.seiscan.app/pacific-1/accounts/${value}`} target="_blank" rel="noopener noreferrer">
                  {truncateAddress(value)}
                </a>
                <button className="copy-button" onClick={() => copyToClipboard(value, index)}>📋</button>
              </div>
              {copiedAddress === `${value}-${index}` && (
                <div className="copy-message">Address copied!</div>
              )}
            </div>
          </div>
        )
      },
      {
        Header: 'Receiving Wallet (SEI Native)',
        accessor: 'toAddress',
        Cell: ({ value, row: { index } }) => (
          <div className="cell-container monospace">
            <div className="address-row-column">
              <div className="address-row">
                <a href={`https://www.seiscan.app/pacific-1/accounts/${value}`} target="_blank" rel="noopener noreferrer">
                  {truncateAddress(value)}
                </a>
                <button className="copy-button" onClick={() => copyToClipboard(value, index)}>📋</button>
              </div>
              {copiedAddress === `${value}-${index}` && (
                <div className="copy-message">Address copied!</div>
              )}
            </div>
          </div>
        )
      },
      {
        Header: 'EVM Wallet',
        accessor: 'evmAddress',
        Cell: ({ value, row: { index } }) => (
          value ? (
            <div className="cell-container monospace">
              <div className="address-row-column">
                <div className="address-row">
                  <a href={`https://seitrace.com/address/${value}`} target="_blank" rel="noopener noreferrer">
                    {truncateAddress(value)}
                  </a>
                  <button className="copy-button" onClick={() => copyToClipboard(value, index)}>📋</button>
                </div>
                {copiedAddress === `${value}-${index}` && (
                  <div className="copy-message">Address copied!</div>
                )}
              </div>
            </div>
          ) : (
            <span>EVM Wallet Not Linked</span>
          )
        )
      },
      {
        Header: (
          <div className="header-content">
            <span>SEI</span>
            <img src={require('../logos/SEI.png')} alt="SEI Logo" className="sei-logo" />
          </div>
        ),
        accessor: 'seiAmount',
        Cell: ({ value }) => value ? value.toLocaleString() : ''
      },
    ];

    if (showUSDC) {
      baseColumns.push({
        Header: (
          <div className="header-content">
            <span>USDC</span>
            <img src={require('../logos/USDC.png')} alt="USDC Logo" className="token-logo" />
          </div>
        ),
        accessor: 'usdcAmount',
        Cell: ({ value }) => value ? value.toLocaleString() : ''
      });
    }

    if (showUSDT) {
      baseColumns.push({
        Header: (
          <div className="header-content">
            <span>USDT</span>
            <img src={require('../logos/USDT.png')} alt="USDT Logo" className="token-logo" />
          </div>
        ),
        accessor: 'usdtAmount',
        Cell: ({ value }) => value ? value.toLocaleString() : ''
      });
    }

    baseColumns.push({
      Header: 'Timestamp',
      accessor: 'timestamp',
      Cell: ({ value }) => value ? new Date(value).toLocaleString().replace(/,/g, '') : ''
    });

    return baseColumns;
  }, [filteredTransactions, copiedAddress, showUSDC, showUSDT]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: filteredTransactions }, useSortBy);

  return (
    <div className="App">
      <div className="header-container">
        <img src={logo} alt="Crafty Canine Logo" className="header-logo" />
        <h1>Bankroll Lookup Tool</h1>
      </div>
      <div className="nav-container" style={{ marginBottom: '10px', marginTop: '-5px' }}>
        <Link to="/">Return to Sniffer Tool</Link>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="textarea-container">
          <textarea
            value={wallet}
            onChange={(e) => setWallet(e.target.value)}
            placeholder="Enter a SEI native address"
            rows="3"
            cols="50"
            disabled={isRunning}
          />
        </div>
        {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
          <button type="submit" disabled={isRunning} style={{ width: '100%' }}>Submit</button>
          {filteredTransactions.length > 0 && (
            <button onClick={() => exportBankrollToCSV(filteredTransactions, wallet)} style={{ width: '100%' }}>Export as CSV</button>
          )}
        </div>
      </form>
      {filteredTransactions.length > 0 && (
        <>
        <div className="bankroll-summary">
          <hr />
          <h3>Summary</h3>
          <p>Total Number of Wallets SEI Has Been Sent To: {transactions.length}</p>
          <p>Total SEI Sent: {totalSeiSent.toLocaleString()}</p>
          {duplicateAddressSummary.length > 0 && (
            <>
              <p>Addresses Sent SEI Multiple Times:</p>
              <ul>
                {duplicateAddressSummary.map((item, index) => (
                  <li key={index} className="monospace">
                    {truncateAddress(item.address)} - {item.totalSei.toLocaleString()} SEI Sent over {item.transactions} transactions
                  </li>
                ))}
              </ul>
            </>
          )}
          {hasDuplicates && (
            <div className="toggle-switch-container">
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  checked={removeDuplicates}
                  onChange={handleToggleDuplicates}
                />
                <span className="slider"></span>
              </label>
              <span className="toggle-label">
                {removeDuplicates ? 'Show Duplicate Receiving Addresses' : 'Remove Duplicate Receiving Addresses'}
              </span>
            </div>
          )}
          <hr />
        </div>

          <div className="table-container">
            <div className="table-scroll-container">
              <table {...getTableProps()} className="results-table">
                <thead>
                  {headerGroups.map(headerGroup => (
                    <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th key={column.id} {...column.getHeaderProps(column.getSortByToggleProps())}>
                          <div className="header-content">
                            <span className="header-text">{column.render('Header')}</span>
                            <span className="sort-arrows">
                              <span>🔼</span>
                              <span>🔽</span>
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map(row => {
                    prepareRow(row);
                    return (
                      <tr key={row.id} {...row.getRowProps()}>
                        {row.cells.map(cell => (
                          <td key={cell.id} {...cell.getCellProps()} className={cell.column.id === 'fromAddress' || cell.column.id === 'toAddress' ? 'monospace' : ''}>
                            {cell.render('Cell')}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
      <Footer />
    </div>
  );
}

export default Bankroll;
