import React, { useState, useEffect } from 'react';
import '../App.css';
import Table from './Table';
import Tooltip from './Tooltip';
import Footer from './Footer';
import { getWalletInfo, findDuplicates, getTokenBalances, getLinkedAddresses } from '../services/api';
import { getWalletBalance } from '../services/evmApi';
import contracts from '../services/contracts';
import { exchangeData } from '../utils/exchangeData';
import logo from '../logos/logo.png';
import { Link } from 'react-router-dom';
import { ethers } from 'ethers';

function App() {
    const [wallets, setWallets] = useState('');
    const [results, setResults] = useState([]);
    const [error, setError] = useState('');
    const [isRunning, setIsRunning] = useState(false);
    const [eta, setEta] = useState(0);
    const [copiedAddress, setCopiedAddress] = useState('');
    const [showEvm, setShowEvm] = useState(true);
    const [selectedTokens, setSelectedTokens] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [showExtraTokens, setShowExtraTokens] = useState(true);
    const [levels, setLevels] = useState(1);

    const exportToCSV = (results) => {
        const headers = [
            'SEI Address', 
            'EVM Address', 
            'Current Balance', 
            'Funding Wallets', 
            'Funding Amount', 
            'Total TXs', 
            'Funding Date',
            ...selectedTokens
        ];

        const csvRows = [
            headers.join(','),
            ...results.map(row => {
                const rowData = [
                    row.wallet_address || '',
                    row.evm_address || '',
                    row.current_balance || '',
                    (row.funding_wallets || []).join('; '),
                    row.amount || '',
                    row.total_tx_count || '',
                    row.timestamp ? new Date(row.timestamp).toLocaleString().replace(/,/g, '') : '',
                    ...selectedTokens.map(token => row[token] || '0')
                ];

                return rowData.join(',');
            })
        ].join('\n');

        const blob = new Blob([csvRows], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'wallet_info.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsRunning(true);

    const walletList = wallets.split(/[\n,]+/).map((wallet, index) => ({
        wallet: wallet.trim(),
        originalIndex: index
    })).filter(walletObj => walletObj.wallet);

    const seiAddresses = walletList.filter(walletObj => walletObj.wallet.startsWith('sei'));
    const evmAddresses = walletList.filter(walletObj => walletObj.wallet.startsWith('0x'));

    // Validate addresses
    for (let { wallet } of seiAddresses) {
        if (!isValidSeiAddress(wallet)) {
            setError(`Invalid SEI address detected: ${wallet}`);
            setIsRunning(false);
            return;
        }
    }

    for (let { wallet } of evmAddresses) {
        if (!ethers.utils.isAddress(wallet)) {
            setError(`Invalid EVM address detected: ${wallet}`);
            setIsRunning(false);
            return;
        }
    }

    try {
        const seiResults = await Promise.all(seiAddresses.map(async ({ wallet, originalIndex }) => {
            // Fetch SEI wallet info
            const walletInfo = await getWalletInfo(wallet, levels);

            // Convert SEI to EVM if needed
            const { evm_address } = await getLinkedAddresses(wallet);

            // Get token balances if tokens are selected
            const tokenBalances = evm_address && selectedTokens.length > 0
                ? await getTokenBalances(evm_address, selectedTokens)
                : {};

            return {
                ...walletInfo,
                evm_address: evm_address || "No EVM Wallet Linked",
                wallet_type: 'sei',
                originalIndex,
                ...tokenBalances
            };
        }));

        const evmResults = await Promise.all(evmAddresses.map(async ({ wallet, originalIndex }) => {
            // Fetch linked SEI address if available
            const { sei_address } = await getLinkedAddresses(wallet);
            
            // Initialize walletInfo with EVM address
            let walletInfo = {
                wallet_address: sei_address || 'SEI Address Not Linked',
                evm_address: wallet,
                wallet_type: 'evm',
                originalIndex,
                funding_wallets: [],
                amount: null,
                timestamp: null,
                total_tx_count: 0,
                current_balance: '0.000000',
            };

            // Get token balances if tokens are selected
            const tokenBalances = selectedTokens.length > 0 ? await getTokenBalances(wallet, selectedTokens) : {};

            if (sei_address) {
                // If linked SEI address is found, get wallet info for it
                const seiWalletInfo = await getWalletInfo(sei_address, levels);
                walletInfo = { ...walletInfo, ...seiWalletInfo, wallet_address: sei_address, ...tokenBalances };
            } else {
                // If no SEI address linked, fetch EVM balance
                const balance = await getWalletBalance(wallet);
                walletInfo.current_balance = balance;
                walletInfo = { ...walletInfo, ...tokenBalances };
            }

            return walletInfo;
        }));

        const combinedResults = [...seiResults, ...evmResults].sort((a, b) => a.originalIndex - b.originalIndex);
        setResults(combinedResults);
        setError('');
    } catch (error) {
        console.error('Error fetching wallet info:', error);
        setError('Error fetching wallet info. Please try again.');
    } finally {
        setIsRunning(false);
    }
};


    const toggleEvmWallet = () => {
        setShowEvm(!showEvm);
    };

    const toggleExtraTokens = () => {
        const newShowState = !showExtraTokens;
        setShowExtraTokens(newShowState);
        if (newShowState) {
            setSelectedTokens(Object.keys(contracts));
        } else {
            setSelectedTokens([]);
        }
    };

    const copyToClipboard = (text, rowIndex) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopiedAddress(`${text}-${rowIndex}`);
            setTimeout(() => {
                setCopiedAddress('');
            }, 5000);
        }, (err) => {
            console.error('Error copying to clipboard', err);
        });
    };

    const handleTokenCheckboxChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setSelectedTokens([...selectedTokens, value]);
        } else {
            setSelectedTokens(selectedTokens.filter(token => token !== value));
        }
    };

    const handleLevelCheckboxChange = (e) => {
        const level = parseInt(e.target.value);
        if (e.target.checked) {
            setLevels(Math.max(levels, level));
        } else {
            setLevels(Math.min(levels, level - 1));
        }
    };

    useEffect(() => {
        if (selectedTokens.length === 0) {
            setShowExtraTokens(false);
        } else {
            setShowExtraTokens(true);
        }
    }, [selectedTokens]);

    const duplicateFundingWallets = findDuplicates(results);
    const duplicateWalletsList = Object.entries(duplicateFundingWallets)
        .filter(([key, value]) => value.length > 1)
        .map(([key, value]) => ({ funding_wallet: key, wallets: value }))
        .sort((a, b) => {
            if (a.funding_wallet === "EVM Funded") return 1;
            if (b.funding_wallet === "EVM Funded") return -1;
            return 0;
        });

    return (
        <div className="App">
            <div className="header-container">
                <img src={logo} alt="Crafty Canine Logo" className="header-logo" />
                <h1>Crafty Canine Address Lookup Tool</h1>
            </div>
            <div className="nav-container">
                <Link to="/bankroll" className="bankroll-link">Go to Bankroll Tool</Link>
            </div>

            <div className="tooltip-container">
                <Tooltip />
            </div>

            <form onSubmit={handleSubmit}>
                <div className="token-checkbox-container">
                    <label onClick={() => setIsExpanded(!isExpanded)}>
                        Check Other Token Balances
                        <span className={`arrow ${isExpanded ? 'up' : 'down'}`}></span>
                    </label>
                    <div className={`token-options ${isExpanded ? 'expanded' : ''}`}>
                        {Object.keys(contracts).map((tokenName) => (
                            <label key={tokenName}>
                                <input
                                    type="checkbox"
                                    value={tokenName}
                                    onChange={handleTokenCheckboxChange}
                                    checked={selectedTokens.includes(tokenName)}
                                />
                                {tokenName}
                            </label>
                        ))}
                    </div>
                </div>
                <div className="textarea-container">
                    <textarea
                        value={wallets}
                        onChange={(e) => setWallets(e.target.value)}
                        placeholder="Enter SEI or EVM addresses, one per line or comma separated"
                        rows="8"
                        cols="50"
                        disabled={isRunning}
                    />
                </div>

                <div className="checkbox-container">
                    <label title="This will check the funding wallet of the first funding wallet.">
                        <input
                            type="checkbox"
                            value={2}
                            onChange={handleLevelCheckboxChange}
                            checked={levels >= 2}
                        />
                        Check 2nd Wallet
                    </label>
                    <label title="This will check the funding wallet of the second funding wallet.">
                        <input
                            type="checkbox"
                            value={3}
                            onChange={handleLevelCheckboxChange}
                            checked={levels >= 3}
                        />
                        Check 3rd Wallet
                    </label>
                    <label title="This will check the funding wallet of the third funding wallet.">
                        <input
                            type="checkbox"
                            value={4}
                            onChange={handleLevelCheckboxChange}
                            checked={levels >= 4}
                        />
                        Check 4th Wallet
                    </label>
                    <label title="This will check the funding wallet of the fourth funding wallet.">
                        <input
                            type="checkbox"
                            value={5}
                            onChange={handleLevelCheckboxChange}
                            checked={levels >= 5}
                        />
                        Check 5th Wallet
                    </label>
                </div>

                <div className="timeout-message-container">
                    <div style={{ fontSize: '11px', color: '#888' }}>
                        Unknown Centralized Exchanges May Cause Tool To Timeout
                    </div>
                </div>

                <div className="info-container">
                    {error && <p className="error">{error}</p>}
                    {isRunning && (
                        <p className="info-text eta-text">
                            Sniffing {wallets.split(/[\n,]+/).filter(wallet => wallet.trim()).length} addresses... ETA: {eta} seconds
                        </p>
                    )}
                </div>
                <div className="submit-container">
                    <button type="submit" disabled={isRunning}>Submit</button>
                </div>
            </form>
            {results.length > 0 && (
                <div className="results-actions">
                    <button onClick={() => exportToCSV(results)}>Export to CSV</button>
                    <div className="toggle-buttons">
                        <div className="toggle-container">
                            <div className={`toggle-switch ${showEvm ? 'on' : ''}`} onClick={toggleEvmWallet}></div>
                            <label className="toggle-label">{showEvm ? 'Hide' : 'Show'} EVM Address</label>
                        </div>
                        <div className="toggle-container">
                            <div className={`toggle-switch ${showExtraTokens ? 'on' : ''}`} onClick={toggleExtraTokens}></div>
                            <label className="toggle-label">{showExtraTokens ? 'Hide' : 'Show'} Token Balances</label>
                        </div>
                    </div>
                </div>
            )}
            {results.length > 0 && (
                <div className="summary-table-container">
                    <Table
                        results={results}
                        copyToClipboard={copyToClipboard}
                        exchangeData={exchangeData}
                        copiedAddress={copiedAddress}
                        showEvm={showEvm}
                        selectedTokens={selectedTokens}
                        duplicateFundingWallets={duplicateWalletsList}
                    />
                </div>
            )}
            <Footer />
        </div>
    );
}

export default App;

function isValidSeiAddress(address) {
    const seiAddressPattern = /^sei[a-zA-Z0-9]{39}$/;
    return seiAddressPattern.test(address);
}

function hasSpecialCharacters(address) {
    const specialCharPattern = /[^a-zA-Z0-9]/;
    return specialCharPattern.test(address);
}
