import coinbaseLogo from '../logos/Coinbase.png';
import mexcLogo from '../logos/MEXC.png';
import binanceLogo from '../logos/Binance.png';
import bybitLogo from '../logos/ByBit.png';
import bitgetLogo from '../logos/Bitget.png';
import gateioLogo from '../logos/GateIO.png';
import kucoinLogo from '../logos/Kucoin.png';
import simpleswapLogo from '../logos/SimpleSwap.png';
import ucexLogo from '../logos/ucex.png'; // Import the Unknown CEX logo
import krakenLogo from '../logos/Kraken.png';

export const exchangeData = {
	 "sei17yfw4umw6cyqmap00gcpy67gl2lzuywy9jgtjn": {
    name: "Coinbase",
    url: "https://www.coinbase.com",
    logo: coinbaseLogo
  },
  "sei19swk34gx92mgdkdaf06980vq9aeasypeh3cxvu": {
    name: "MEXC",
    url: "https://www.mexc.com",
    logo: mexcLogo
  },
  "sei1yununhk40dm5qje0mchljvey9adt876uyrzf4n": {
    name: "Coinbase",
    url: "https://www.coinbase.com",
    logo: coinbaseLogo
  },
    "sei1z8jrrat54wsq6yyhsgjuurmjaxrnxjvpjn8mql": {
    name: "Coinbase",
    url: "https://www.coinbase.com",
    logo: coinbaseLogo
  },
  "sei1wrz9tveueyqqw6zesuae0qvh6natxumknlvvp8": {
    name: "Binance",
    url: "https://www.binance.com",
    logo: binanceLogo
  },
    "sei1zrqryvr8m40yqej95lcqzw7y0sf0662cxh0kcv": {
    name: "Binance (Deposits)",
    url: "https://www.binance.com",
    logo: binanceLogo
  },
  "sei155svs6sgxe55rnvs6ghprtqu0mh69kehwl7mxz": {
    name: "Gate.io",
    url: "https://www.gate.io/",
    logo: gateioLogo
  },
  "sei1wxz8xxevhgsgt67cjy4vrnj3w469tka4m03gg6": {
    name: "Bitget",
    url: "https://www.bitget.com/",
    logo: bitgetLogo
  },
  "sei1hlwckhdaa26mg52gfttcm9t7qspcqx3lzylel8": {
    name: "Kucoin",
    url: "https://www.kucoin.com/",
    logo: kucoinLogo
  },
    "sei1vwda8z8rpwcagd5ks7uqr3lt3cw9464hhswfe7": {
    name: "Kucoin (Deposits)",
    url: "https://www.kucoin.com/",
    logo: kucoinLogo
  },  
  "sei1nzddkg7wfl8mna4kz2yyxg4um4wdpysv9qdkx0": {
    name: "SimpleSwap",
    url: "https://simpleswap.io/",
    logo: simpleswapLogo
  },
  "sei19lx5pghv2xggm2r9yrkp4v55g7rrz9300t4ap5": {
    name: "ByBit",
    url: "https://www.bybit.com/en/",
    logo: bybitLogo
  },
    "sei10czwjwhcjdjexgz9ecn2xma5at9t04dfaxsf4t": {
    name: "ByBit (Deposits)",
    url: "https://www.bybit.com/en/",
    logo: bybitLogo
  },
    "sei1nm0rrq86ucezaf8uj35pq9fpwr5r82cl2ufg84": {
    name: "Kraken",
    url: "https://www.kraken.com",
    logo: krakenLogo
  } 
  
  
};
