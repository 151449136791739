import React, { useEffect, useRef, useState } from 'react';
import './Tooltip.css';
import { exchangeData } from '../utils/exchangeData';
import decoderLogo from '../logos/decoder.png'; // Ensure the path to the decoder logo is correct
import Icon from '../icons/Info.png'; // Import the icon

const Tooltip = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const modalRef = useRef(null);

  const toggleTooltip = (e) => {
    e.stopPropagation(); // Prevent event from propagating to the form
    setIsOpen(!isOpen);
    if (isOpen) {
      setIsExpanded(false); // Reset the expanded state when closing the tooltip
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsOpen(false);
      setIsExpanded(false); // Reset the expanded state when clicking outside
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  // Group wallets by exchange name
  const groupedExchanges = Object.entries(exchangeData).reduce((acc, [address, { name, logo }]) => {
    if (!acc[name]) {
      acc[name] = { logo, addresses: [] };
    }
    acc[name].addresses.push(address);
    return acc;
  }, {});

  return (
    <div className="tooltip-wrapper">
      <div className="tooltip-button-wrapper">
        <button className="tooltip-button" onClick={toggleTooltip}>
          <img src={Icon} alt="Info Icon" className="icon-image" />
        </button>
        <span className="tooltip-text">More Info</span>
      </div>
      {isOpen && (
        <div className="overlay">
          <div className="info-modal" ref={modalRef}>
            <h2>Tool Information</h2>
           
            <p>
              This tool queries the first 10,000 TXs of a wallet using the <a href="https://celat.one/chains/pacific-1" target="_blank" rel="noopener noreferrer" className="link">Celatone API</a>. <br />
              Queries take longer the more TXs a wallet has, the average time is  ~2 wallets per second. This tool is limited to 1,000 addresses, if you wish to check more than 1,000 addresses, please reach out to <a href="https://x.com/CryptoD4wg" target="_blank" rel="noopener noreferrer" className="link">Kryme</a> on Twitter.
            </p>
			
			 <p>
              <strong>Bankroll Tool</strong><br />
              This tool displays all the wallets an address has sent SEI to.
            </p>
            <p className="evm-warning">Native EVM Funding Wallets Are Not Currently Supported!</p>
            <p className="special-thanks">
              <img src={decoderLogo} alt="decoderdev" className="special-thanks-logo" /> Special thanks to decoderdev from Massdrop for help with the EVM side of this site.
            </p>

            <h3 onClick={toggleExpand} className="collapsible-header">
              Known CEX Wallets: {isExpanded ? '▲' : '▼'}
            </h3>
            {isExpanded && (
              <ul className="exchange-list">
                {Object.entries(groupedExchanges).map(([name, { logo, addresses }], index) => (
                  <li key={index} className="exchange-item">
                    <div className="exchange-header">
                      <img src={logo} alt={name} className="exchange-logo" /> {name}
                    </div>
                    <ul className="wallet-list">
                      {addresses.map((address, idx) => (
                        <li key={idx} className="wallet-item">
                          <span className="dash">—</span> 
                          <a href={`https://www.seiscan.app/pacific-1/accounts/${address}`} target="_blank" rel="noopener noreferrer" className="wallet-link">
                            {address}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            )}
            <button onClick={toggleTooltip} className="close-button">Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
