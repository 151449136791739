import axios from 'axios';
import { ethers } from 'ethers';
import addrAbi from '../abis/addr.json'; // Ensure the path to addr.json is correct
import contracts from './contracts'; // Import contracts
import { exchangeData } from '../utils/exchangeData'; // Import exchangeData

const evm_base_url = "https://evm-rpc.sei-apis.com/?x-apikey=d0227c6f"; // Your EVM RPC URL
const contractAddress = "0x0000000000000000000000000000000000001004"; // Precompile contract address for address conversion
const provider = new ethers.providers.JsonRpcProvider(evm_base_url);
const addressPrecompileContract = new ethers.Contract(contractAddress, addrAbi, provider);

export const getWalletInfo = async (wallet_address, levels = 3) => {
  const base_url = "https://celatone-api-prod.alleslabs.dev/v1/sei/pacific-1/accounts";
  const params = {
    'limit': 10000,
    'offset': 0
  };

  const fundingWallets = [];
  let originalWalletData = null;
  let currentWallet = wallet_address;

  try {
    console.log(`Fetching wallet info for ${wallet_address}`);

    for (let i = 0; i < levels; i++) {
      console.log(`Fetching level ${i + 1} data for ${currentWallet}`);
      
      const [txResponse, balanceResponse] = await Promise.all([
        axios.get(`${base_url}/${currentWallet}/txs`, { params }),
        axios.get(`${base_url}/${currentWallet}/balances`)
      ]);

      const txData = txResponse.data;
      const balanceData = balanceResponse.data;
      const txs = txData.items || [];
      let first_inbound_tx = null;
      let funding_wallet = null;
      let amount = null;
      let timestamp = null;

      for (let tx of txs.reverse()) {
        for (let message of tx.messages) {
          const detail = message.detail || {};
          if (detail.to_address === currentWallet) {
            first_inbound_tx = tx.hash;
            funding_wallet = detail.from_address;
            const raw_amount = detail.amount ? detail.amount[0].amount : null;
            if (raw_amount) {
              amount = (parseInt(raw_amount) / 1_000_000).toFixed(6);
            }
            timestamp = tx.created;
            break;
          }
        }
        if (first_inbound_tx) {
          break;
        }
      }

      if (i === 0) {
        // Store original wallet data
        const relevant_txs = txs.filter(tx => tx.sender !== funding_wallet);
        const relevant_tx_count = relevant_txs.length;
        const balance = balanceData.find(b => b.denom === 'usei');
        const current_balance = balance ? (parseInt(balance.amount) / 1_000_000).toFixed(6) : '0.000000';
        const total_tx_count = txs.length;

        originalWalletData = {
          wallet_address,
          funding_wallet: funding_wallet || "EVM Funded",
          amount,
          timestamp,
          total_tx_count,
          relevant_tx_count,
          current_balance
        };

        console.log('Original Wallet Data:', originalWalletData);
      }

      if (funding_wallet) {
        fundingWallets.push(funding_wallet);
        if (exchangeData[funding_wallet]) {
          console.log(`Funding wallet ${funding_wallet} is an exchange wallet. Stopping.`);
          break; // Stop if the funding wallet is an exchange wallet
        }
        currentWallet = funding_wallet;
      } else {
        break;
      }
    }

    return {
      ...originalWalletData,
      funding_wallets: fundingWallets
    };
  } catch (error) {
    console.error(`Error fetching data for wallet ${wallet_address}:`, error);
    return {
      wallet_address,
      funding_wallets: ["EVM Funded"],
      funding_wallet: "EVM Funded",
      amount: null,
      timestamp: null,
      total_tx_count: 0,
      relevant_tx_count: 0,
      current_balance: '0.000000'
    };
  }
};

export const getTokenBalances = async (evm_address, selectedTokens) => {
  const tokenBalances = {};

  if (!evm_address) {
    // If no EVM address, return zero balances for selected tokens
    console.log('No EVM address provided. Returning zero balances.');
    for (const tokenName of selectedTokens) {
      tokenBalances[tokenName] = '0.000000';
    }
    return tokenBalances;
  }

  console.log(`Fetching token balances for EVM address ${evm_address}`);

  for (const [tokenName, tokenAddress] of Object.entries(contracts)) {
    if (selectedTokens.includes(tokenName)) {
      const contract = new ethers.Contract(
        tokenAddress,
        ['function balanceOf(address owner) view returns (uint256)'],
        provider
      );

      try {
        const balance = await contract.balanceOf(evm_address);
        tokenBalances[tokenName] = ethers.utils.formatUnits(balance, 18); // Adjust decimals if necessary
        console.log(`Balance of ${tokenName} for ${evm_address}: ${tokenBalances[tokenName]}`);
      } catch (error) {
        console.error(`Error fetching ${tokenName} balance for wallet ${evm_address}:`, error);
        tokenBalances[tokenName] = '0.000000';
      }
    }
  }

  return tokenBalances;
};

export const getLinkedAddresses = async (address) => {
  console.log(`Fetching linked addresses for ${address}`);

  if (address.startsWith("0x")) {
    try {
      const seiAddress = await addressPrecompileContract.getSeiAddr(address);
      console.log(`EVM address ${address} maps to SEI address ${seiAddress}`);
      return { evm_address: address, sei_address: seiAddress };
    } catch (error) {
      console.error(`Error fetching SEI address for EVM address ${address}:`, error);
      return { evm_address: address, sei_address: "" };
    }
  } else if (address.startsWith("sei")) {
    try {
      const evmAddress = await addressPrecompileContract.getEvmAddr(address);
      console.log(`SEI address ${address} maps to EVM address ${evmAddress}`);
      return { sei_address: address, evm_address: evmAddress };
    } catch (error) {
      console.error(`Error fetching EVM address for SEI address ${address}:`, error);
      return { sei_address: address, evm_address: "" };
    }
  }
  console.warn(`Address ${address} is neither EVM nor SEI. Returning empty addresses.`);
  return { evm_address: "", sei_address: "" };
};

export const exportToCSV = (results) => () => {
  const headers = [
    'Wallet Address', 
    'EVM Wallet Address', 
    'Current Balance', 
    'Funding Wallet', 
    'SEI Amount', 
    'Total TXs', 
    'Funding Date', 
    ...Object.keys(contracts)
  ];

  const csvRows = [headers.join(',')];

  results.forEach(result => {
    const row = [
      result.wallet_address,
      result.evm_address || 'N/A',
      `"${result.current_balance}"`, // Wrap in double quotes
      result.funding_wallet,
      `"${result.amount}"`, // Wrap in double quotes
      result.total_tx_count,
      result.timestamp ? new Date(result.timestamp).toLocaleString().replace(/,/g, '') : '',
      ...Object.keys(contracts).map(tokenName => 
        result[tokenName] ? `"${parseInt(result[tokenName]).toLocaleString()}"` : 'N/A'
      )
    ];
    csvRows.push(row.join(','));
  });

  const csvContent = `data:text/csv;charset=utf-8,${csvRows.join('\n')}`;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'wallet_info.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const findDuplicates = (arr) => {
  const duplicates = {};
  arr.forEach((item) => {
    const fundingWallet = item.funding_wallet === "EVM Funded" ? "EVM Funded" : item.funding_wallet;
    if (duplicates[fundingWallet]) {
      duplicates[fundingWallet].push(item.wallet_address);
    } else {
      duplicates[fundingWallet] = [item.wallet_address];
    }
  });
  console.log('Duplicates found:', duplicates);
  return duplicates;
};
